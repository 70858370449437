import { Select, Input, Radio } from "antd";
import { useState, useEffect } from "react";
import { useAlfgoState } from "../../AlfgoContext";
import Title from "antd/es/skeleton/Title";


function Guest({guest, guestIndex, roomIndex}) {

    const {state, dispatch} = useAlfgoState();
    const [currentGuest, setCurrentGuest] = useState({});

    useEffect(() => {
        setCurrentGuest(guest);
    }, []);

    useEffect(() => {

        const cartItems = [...state.common.cart.items];
        cartItems[roomIndex]['guests'][guestIndex] = currentGuest;
        dispatch({type: "SET_CART_ITEMS", payload: cartItems});

    }, [currentGuest]);

    const options = [
        {
            value: 'Mr',
            label: 'Mr'
        },
        {
            value: 'Mrs',
            label: 'Mrs'
        }
    ]

    const leadGuestChange = (e) => {
        const cartItems = [...state.common.cart.items];
        let currentItemGuests = cartItems[roomIndex]['guests'];

        currentItemGuests = currentItemGuests.map((guest) => {
            guest.IsLeadPax = false;
            return guest;
        });

        cartItems[roomIndex]['guests'][guestIndex]['IsLeadPax'] = true;

        dispatch({type: "SET_CART_ITEMS", payload: cartItems});

    }

    return (
        <div className="alfg-guests__room-row gap-4">

            <Select
                options={options}
                className='w-100'
                value={currentGuest.Title}
                onChange={(e) => {
                    const guest = {...currentGuest, Title: e}
                    setCurrentGuest(guest);
                }}
            />

            <Input
                placeholder='First name'
                className='w-100'
                value={currentGuest.FirstName}
                onChange={(e) => {
                    const guest = {...currentGuest, FirstName: e.target.value}
                    setCurrentGuest(guest);
                }}
            />

            <Input
                placeholder='Last name'
                className='w-100'
                value={currentGuest.LastName}
                onChange={(e) => {
                    const guest = {...currentGuest, LastName: e.target.value}
                    setCurrentGuest(guest);
                }}
            />


            <Radio onChange = {(e) => {leadGuestChange(e)}} checked={currentGuest.IsLeadPax ? true && currentGuest.IsLeadPax !== 'false' : false} className="justify-center" />

        </div>
    )
}

export default Guest

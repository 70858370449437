import { useAlfgoState } from "../../AlfgoContext";
import { useEffect, useState } from "react";
import { Guest } from "./index";

function Rooms() {
    const { state, dispatch } = useAlfgoState();
    const [rooms, setRooms] = useState([]);

    useEffect(() => {
        setRooms(state.common.cart.items);

    }, [state]);

    useEffect(() => {
        // console.log(rooms);
    }, [rooms])



    return (

        <>

            {rooms.length > 0 && rooms.map((room, roomIndex) => (

                <div key={`${room.hotelCode}-${roomIndex}`} className="alfg-guests__room">
                    <div className="alfg-guests__room-heading ">Room {roomIndex + 1}</div>
                    <div className="alfg-guests__room-inputs flex flex-col gap-2">

                        <div className="alfg-guests__room-row mb-4 mt-2">
                            <div className="pax-detail strong text-base">Pax details</div>
                            <div className="lead strong flex justify-center text-base">Lead Guests</div>
                        </div>


                        {(room.guests).length > 0 && (room.guests).map((guest, index) => (
                            <Guest key={index} guest={guest} guestIndex={index} roomIndex={roomIndex}/>
                        ))}

                    </div>
                </div>

            ))}



        </>
    )
}

export default Rooms

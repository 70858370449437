import { useEffect, useState } from "react";
import { useAlfgoState } from "../../AlfgoContext";
import { Rate, Table, Button } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import axios from 'axios';  // Ensure Axios is imported

function Sidebar() {
    const { state, dispatch } = useAlfgoState();
    const [hotels, setHotels] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [bookLoading, setBookLoading] = useState(false);

    const columns = [
        {
            title: 'Room',
            dataIndex: 'room',
            key: 'room',
        },
        {
            title: 'Check In',
            dataIndex: 'checkIn',
            key: 'checkIn',
        },
        {
            title: 'Check Out',
            dataIndex: 'checkOut',
            key: 'checkOut',
        },
    ]

    useEffect(() => {

        const hotelSet = {};
        let allRooms = [];
        let total = 0;


        const cartItems = state.common.cart.items;


        cartItems.forEach((item) => {
            const hotelCode = item.hotel.hotelCode;

            let rooms = [];
            let hotelRooms = [{ ...item.room, guests: item.guests }];
            if (hotelSet.hasOwnProperty(hotelCode)) {
                hotelRooms = [...hotelSet[hotelCode].rooms, { ...item.room, guests: item.guests }];
            }

            rooms.push({ ...item.room, guests: item.guests });

            hotelSet[hotelCode] = { ...item.hotel, rooms: hotelRooms }
            allRooms = [...allRooms, ...rooms]

            total += item.room.price.net;
        })

        setRooms(allRooms);
        setHotels(Object.values(hotelSet));
        setTotalAmount(total);
        // console.log("state.common.cart.items", state.common.cart.items);
    }, [state]);

    useEffect(() => {

        // console.log("rooms", rooms);
        // console.log("hotels", hotels);
    }, [hotels, rooms])

    const handleBack = () => {
        dispatch({ type: "SET_IS_CART_DETAIL", payload: !state.common.isCartDetail })
    }

    const bookClick = () => {
    setBookLoading(true);
    // console.log("cart", state.common.cart);

    // Check if every guest has a first name and last name
    for (const item of state.common.cart.items) {
        for (const guest of item.guests) {
            if (!guest.FirstName || !guest.LastName) {
                alert("Every guest must have a first name and last name.");
                setBookLoading(false);
                return;
            }
        }
    }

    const data = {
        action: "book_hotels_cart",
        nonce: alhb_obj.nonce,
        cart_data: state.common.cart
    };

    jQuery.post(alhb_obj.ajaxUrl, data, function(resp){
        setBookLoading(false);

        if(resp.status === 'success'){
            window.location.href = '/checkout';
        } else {
            alert(resp.message);
        }
    });
};

    return (
        <div className="sticky top-32">
            {(hotels.length > 0) && hotels.map((hotel, index) => {

                const dataSource = [{
                    room: (hotel.rooms).length,
                    checkIn: hotel.checkIn,
                    checkOut: hotel.checkOut,
                }];

                return (

                    <div className={`p-2  mt-4 ${index !== 0 ? 'pt-6 border-t-2 border-solid' : ''}`}>
                        <div className="gap-4" style={{ display: "grid", gridTemplateColumns: "25% 1fr" }}>
                            <div className="w-100 relative" style={{ paddingBottom: "70%" }}>
                                <img className="absolute top-0 left-0 w-100 h-100 object-cover" src={hotel.image} alt="" />
                            </div>
                            <div className="flex flex-col gap-1">
                                <span className="text-2xl font-bold">{hotel.name}</span>
                                <span>
                                    <Rate allowHalf defaultValue={5} className="text-2xl" />
                                </span>
                                <span className="text-xl">{hotel.address}</span>
                            </div>
                        </div>
                        <div>
                            <Table dataSource={dataSource} columns={columns} pagination={false} />
                        </div>
                    </div>
                )
            }

            )}

            <div className="border-solid border border-slate-200 rounded p-2 flex flex-col gap-6">
                <h3 className="text-2xl font-bold text-primary">Fare Summary</h3>


                <div className="flex flex-col gap-4">

                    {(rooms.length > 0) && rooms.map((room, index) => {

                        return (
                            <div className="flex justify-between gap-8 border-b border-solid pb-2">
                                <span className="text-2xl w-9/12">{room.roomName}</span>
                                <span className="text-2xl w-3/12">{room.price.supplierCurrency} {parseFloat(room.price.net).toFixed(2)}</span>
                            </div>
                        )
                    })}

                </div>

                <div className="flex justify-between gap-8 items-center mt-4">
                    <span className="text-2xl">Total Amount <br /> (inc. Tax)</span>
                    <span className="text-2xl">AED {parseFloat(totalAmount).toFixed(2)}</span>
                </div>

                <div className="flex gap-2 flex-grow">
                    <Button onClick={handleBack} icon={<ArrowLeftOutlined />} className="flex-grow">Back</Button>
                    <Button type="primary" icon={<ArrowRightOutlined />} className="flex-grow" iconPosition="end" onClick={bookClick} loading={bookLoading}>Book</Button>
                </div>



            </div>
        </div>

    )
}

export default Sidebar

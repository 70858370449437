import "./CartDetail.css"
import {useAlfgoState} from "../../AlfgoContext";
import {Button, Form, Select, Input, Radio} from "antd";
import {useEffect, useState} from "react";
import {Rooms, Sidebar, Tours} from "./index"

const CartDetail = () => {
    const {state, dispatch} = useAlfgoState();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Function to update the state with the new window width
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        // Add event listener to update width on window resize
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    // temp

    useEffect(() => {
        // const sessionCart = localStorage.getItem('currentCart');
        // if((state.common.cart.items).length === 0 && sessionCart){
        // 	dispatch({type: "SET_CART", payload: JSON.parse(sessionCart)});
        // }
    }, []);

    const handleBack = () => {
        dispatch({type: "SET_IS_CART_DETAIL", payload: !state.common.isCartDetail})
    }


    return (
        <div className="alfg-detail">
            <div className="alfg-detail__container">
                <div className="alfg-detail__main">
                    <div className="alfg-detail__content flex flex-col gap-6">

                        <div>
                            <h4 className="font-bold text-2xl">Booking information</h4>
                            <p>Please fill all the guests details if there are extra fields just write NA<span
                                className='text-red-600'>*</span></p>
                        </div>
                        <div className="alfg-guests">
                            <div className="alfg-guests__container gap-8 justify-between">
                                <Rooms/>
                            </div>
                        </div>

                        {screenWidth > 992 &&<div className="alfg-tours">
                            <Tours/>
                        </div>
                        }

                    </div>

                    <div className="alfg-detail__sidebar border-solid border border-slate-200 rounded p-2">
                        <Sidebar/>
                    </div>

                    {screenWidth <= 992 &&<div className="alfg-tours">
                        <Tours/>
                    </div>
                    }

                </div>
            </div>
        </div>
    )
}


export default CartDetail;

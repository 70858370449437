import { useState, useCallback } from 'react';

const useBookHotelsCart = () => {
	const [loading, setLoading] = useState(false);
	const [cartError, setCartError] = useState(null);
	const [cart, setCart] = useState(null);

	const bookHotelsCart = useCallback(async (cartData) => {
		setLoading(true);
		setCartError(null);

		const data = {
			action: "book_hotels_cart_temp",
			nonce: alhb_obj.nonce,
			cart_data: cartData
		};

		jQuery.post(alhb_obj.ajaxUrl, data, function(resp){
			setLoading(false);
			if(resp.status === 'success'){
				if(resp.cart){
					setCart(resp.cart);

					if(resp.cart['span.cart-count']){
						jQuery('.cart-icon .cart-count').replaceWith(resp.cart['span.cart-count']);
					}

					if(resp.cart['div.widget_shopping_cart_content']){
						jQuery('div.widget_shopping_cart_content').replaceWith(resp.cart['div.widget_shopping_cart_content']);
					}

					if(resp.cart['span.cart-subtotal']){
						jQuery('span.cart-subtotal').replaceWith(resp.cart['span.cart-subtotal']);
					}
				}
			} else {
				setCartError("There is an error while booking hotels cart");
			}
		});

	}, []);

	return { bookHotelsCart, loading, cartError, cart };
};

export default useBookHotelsCart;

import { Select } from 'antd';

function FilterRatings({ratingChange, filterRatings}) {


    const options = [
        {
            value: 0,
            label: 'No rating'
        },
        {
            value: 1,
            label: '⭐'
        },
        {
            value: 2,
            label: '⭐⭐'
        },
        {
            value: 3,
            label: '⭐⭐⭐'
        },
        {
            value: 4,
            label: '⭐⭐⭐⭐'
        },
        {
            value: 5,
            label: '⭐⭐⭐⭐⭐'
        },
        {
            value: 6,
            label: '⭐⭐⭐⭐⭐⭐'
        }
    ];

    return (
            <Select
                showSearch
                mode="multiple"
                placeholder="Select Rating"
                name="rating"
                optionFilterProp="children"
                onChange={(value) => {ratingChange(value);}}
                options={options}
                size="large"
                style={{ width: '100%' }}
                maxTagCount="responsive"
                value={filterRatings}
            />
    );
}

export default FilterRatings;

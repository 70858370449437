import React, { createContext, useReducer, useContext } from 'react';

let initialFilterState = {
    destination: null,
    nationality: null,
    ratings: [0, 1, 2, 3, 4, 5, 6],
    startDate: "",
    endDate: "",
    hotelName: "",
    guests: [
        {
            adult: 1,
            children: 0,
            ages: []
        }
    ],
};

if (alhb_obj.filters && (alhb_obj.filters).hasOwnProperty('destination')) {
    initialFilterState = { ...alhb_obj.filters };
}

const initialCommonState = {
    isHotels: false,
	selectedHotel: "",
	sessionId: "",
    isCartDetail: false,
    cart: {
        sessionId: "",
        items: []
    }
};

if (alhb_obj.hotelCart && (alhb_obj.hotelCart).hasOwnProperty('sessionId')) {
	initialCommonState.cart = { ...alhb_obj.hotelCart };
}


// Alfgo initial state
const initialState = {
    filter: initialFilterState,
    common: initialCommonState,
};
// Alfgo Reducer
const alfgoReducer = (state, action) => {
    switch (action.type) {
        // Filter
        case 'SET_IS_CART_DETAIL':
            return {
                ...state,
                common: {
                    ...state.common,
                    isCartDetail: action.payload
                }
            }
        case 'SET_CART':
            return {
                ...state,
                common: {
                    ...state.common,
                    cart: action.payload
                }
            }

        case 'SET_CART_ITEMS':

            const newState = {
                ...state,
                common: {
                    ...state.common,
                    cart: {
                        ...state.common.cart,
                        items: action.payload
                    }
                }
            };

            localStorage.setItem('currentCart', JSON.stringify(newState.common.cart));

            return newState;
            return {
                ...state,
                common: {
                    ...state.common,
                    cart: {
                        ...state.cart.common,
                        items: action.payload
                    }
                }
            }
		case 'SET_SESSION_ID':
			return {
				...state,
				common: {
					...state.common,
                    cart: {
                        ...state.common.cart,
                        sessionId: action.payload
                    }
				}
			}
        case 'SET_DESTINATION':
            return { ...state,
				filter: {
					...state.filter, destination: action.payload
				}
			};
        case 'SET_NATIONALITY':
            return { ...state,
				filter: {
					...state.filter, nationality: action.payload
				}
			};
        case 'SET_DATES':
            return {
				...state,
				filter: {
					...state.filter, startDate: action.payload[0], endDate: action.payload[1]
				}
			};
        case 'ADD_NEW_ROOM':
            return {
				...state,
				filter: {
					...state.filter, guests: [...state.filter.guests, action.payload]
				}
			};
        case 'REMOVE_ROOM':
            return {
				...state,
				filter: {
					...state.filter,
					guests: state.filter.guests.filter((_, index) => index !== action.payload.index)
				}
			};
        case 'SET_ADULT':
            return {
                ...state,
                filter: {
                    ...state.filter,
                    guests: state.filter.guests.map((guest, index) =>
                        index === action.payload.index ? { ...guest, adult: action.payload.adult } : guest
                    ),
                },
            };
        case 'SET_CHILDREN':
            return {
                ...state,
                filter: {
                    ...state.filter,
                    guests: state.filter.guests.map((guest, index) => {
                        if (index === action.payload.index) {
                            const newChildren = action.payload.children;
                            const newAges = [...guest.ages];

                            if (newChildren > guest.children) {
                                for (let i = guest.children; i < newChildren; i++) {
                                    newAges.push(1);
                                }
                            } else {
                                newAges.length = newChildren;
                            }

                            return { ...guest, children: newChildren, ages: newAges };
                        }
                        return guest;
                    }),
                },
            };
        case 'SET_RATING':
            return {
				...state,
					filter: { ...state.filter, ratings: action.payload.ratings}
			};
        case 'SET_HOTEL_NAME':
            return {
				...state,
				filter: { ...state.filter, hotelName: action.payload.hotelName }
			};
        case 'SET_FILTER':
            return {
				...state,
				filter: { ...state.filter, ...action.payload }
			};
        // Common Actions
        case 'SET_IS_HOTELS':
            return {
				...state,
				common: { ...state.common, isHotels: action.payload }
			};
        default:
            return state;
    }
};

// Create Context
const AlfgoContext = createContext();

// Provider Component
export const AlfgoProvider = ({ children }) => {
    const [state, dispatch] = useReducer(alfgoReducer, initialState);

    return (
        <AlfgoContext.Provider value={{ state, dispatch }}>
            {children}
        </AlfgoContext.Provider>
    );
};

// Custom Hook to use the AlfgoContext
export const useAlfgoState = () => {
    return useContext(AlfgoContext);
};

import {useAlfgoState} from "../../AlfgoContext";
import {useEffect} from "react";
import {useState} from "react";
import {ArrowRightOutlined} from "@ant-design/icons";
import {Button} from "antd";

function Tours() {
    const {state, dispatch} = useAlfgoState();
    const [tours, setTours] = useState([]);
    const [days, setDays] = useState(0);

    const calculateMaxDays = (cart) => {
        if (!cart.items || cart.items.length === 0) {
            return 0;
        }


        const daysArray = cart.items.map(item => {
            const checkInDate = new Date(item.hotel.checkIn);
            const checkOutDate = new Date(item.hotel.checkOut);
            const differenceInTime = checkOutDate - checkInDate;
            const differenceInDays = differenceInTime / (1000 * 3600 * 24); // Convert milliseconds to days
            return differenceInDays;
        });

        // Get the maximum value from the array of days
        return Math.max(...daysArray);
    };

    useEffect(() => {
        if ((state.common.cart.items).length > 0) {
            const maxDays = calculateMaxDays(state.common.cart);
            let destination = state.common.cart.items[0].hotel.DestinationCode;
            jQuery.get(alhb_obj.ajaxUrl, {
                action: 'get_tours_less_than_days',
                nonce: alhb_obj.nonce,
                days: maxDays,
                destination: destination
            }, function (resp) {
                if (resp.success) {
                    setTours(resp.data);

                }
            });
        }
    }, [state.common.cart]);

    useEffect(() => {
        // console.log("This is tours", tours);
    }, [tours]);
    return (

        <div className="flex flex-col gap-6 mt-4 md:mt-6">
            <div className="">
                <h2 className="font-bold text-3xl">Suggested tours you may like</h2>
            </div>

            <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
                {tours.length > 0 && tours.map((tour, index) => (
                <a  key={tour.id} href={tour.url}
                   className="flex flex-col gap-3">
                    <div className="relative w-full overflow-hidden" style={{paddingBottom: '100%'}}>
                        <img
                            src={tour.image_url}
                            className="absolute top-0 left-0 w-full h-full object-cover transform transition-transform duration-300 ease-in-out hover:scale-110" alt=""
                            decoding="async"
                        />
                    </div>

                    <div className="flex flex-col gap-6 justify-between flex-grow">
                        <h6 className="text-2xl">{tour.title}</h6>

                        <Button type="primary" icon={<ArrowRightOutlined />} className="align-end mt-4 mb-4" iconPosition="end" >Book tour</Button>
                    </div>

                </a>))}
            </div>

        </div>
    )
}

export default Tours

import {useAlfgoState} from "../../AlfgoContext.jsx";
import {useEffect, useState} from "react";
import {useQuery} from "react-query";
import {getHotels} from "../../api/index.js";
import {Table} from "antd";
import { v4 as uuidv4 } from 'uuid';
import {useBookHotelsCart} from "../../api";

const HotelPopup = ({hotel}) => {
	const {state, dispatch} = useAlfgoState();
	const [rooms, setRooms] = useState([]);
	const [mealFilter, setMealFilter] = useState([]);
	const { bookHotelsCart, loading, cartError, cart } = useBookHotelsCart();

	useEffect(() => {

	}, [state.common.cart]);

	useEffect(() => {
		let newMealFilter = rooms.map((room) => {
			return {
				text: room.meal,
				value: room.meal,
			};
		});

		// Create a Set to keep track of unique values
		const uniqueValues = new Set();
		newMealFilter = newMealFilter.filter(item => {
			const isUnique = !uniqueValues.has(item.value);
			uniqueValues.add(item.value);
			return isUnique;
		});

		setMealFilter(newMealFilter);
	}, [rooms]);

	const bookRoom = (room) => {
		 if(loading) return;
		 const cartItems = state.common.cart.items;
		 const filterGuests = state.filter.guests;


		 const guests = [];
		 const singleGuest = {
			IsLeadPax: false,
			FirstName: "",
			LastName: "",
			Title: "Mr",
			Age: 6
		 }


		const currentHotel = {
			hotelCode: hotel.code,
			DestinationCode: state.filter.destination,
			groupCode: hotel.groupCode,
			name: hotel.name,
			image: hotel.hotelInfo.image,
			address: `${hotel.hotelInfo.add1}, ${hotel.hotelInfo.add2}`,
			checkIn: state.filter.startDate,
			checkOut: state.filter.endDate,
		}

		const newCartItem = {
			hotel: currentHotel,
			room,
			guests,
			itemId: uuidv4()
		}

		// console.log(cartItems);
		// console.log("filterGuests", filterGuests);
		if(cartItems.length < filterGuests.length ){
			const roomNo = cartItems.length;
			const adult = filterGuests[roomNo].adult;

			for (let i = 0; i < adult; i++) {
				let isLeadPax = i === 0;
				guests.push({ ...singleGuest, IsLeadPax: isLeadPax });
			}

			dispatch({type: 'SET_CART_ITEMS', payload: [...cartItems, newCartItem]});

		}else{
			alert(`Only ${filterGuests.length} room/s are allowed, change filter to add more rooms!`);
		}
	}

	useEffect(() => {
		bookHotelsCart(state.common.cart);
	}, [state.common.cart]);

	const tableColumns = [
		{
			title: 'Room Name',
			dataIndex: 'roomName',
			key: 'roomName',
		},
		{
			title: 'Adult',
			dataIndex: 'adult',
			key: 'adult',
		},
		{
			title: "Meal",
			dataIndex: 'meal',
			key: 'meal',
			filters: mealFilter,
			onFilter: (value, record) => {
				return record.meal === value;
			}
		},
		{
			title: "Price",
			dataIndex: 'price',
			key: 'price',
			render: (price, room) => (
				<span style={{display: "flex", justifyContent: "space-between", background: "rgba(255 132 25 0.2)"}}>
					<span>{`${price.supplierCurrency} ${price.net}`}</span>
					<button onClick={() => bookRoom(room)}>{"Book now"}</button>
				</span>
			)
		}
	]

	let queryParams = {
		'action': "alfg_get_rooms",
		'data': {
			rooms: state.filter.guests,
			hotelCode: hotel.code,
			sessionId: state.common.cart.sessionId
		},
		'nonce': alhb_obj.nonce
	}
	const { data, error, isLoading } = useQuery(['alfgHotel', queryParams], () => getHotels(queryParams), {
		enabled: !!queryParams, // This ensures the query runs only if queryParams is not null
	});

	useEffect(() => {

		if(!isLoading && !error && data?.hotel?.rooms?.room){
			setRooms(data.hotel.rooms.room);
		}

	}, [data])

	return (
		<div className="alfg-card__popup relative" style={{padding: "1rem"}}>

			{
				isLoading &&
				<div style={{textAlign: "center", padding: "1rem"}}>Loading rooms...</div>
			}
			{
				!isLoading && !error && rooms &&
				<Table columns={tableColumns} dataSource={rooms} />
			}
            {!isLoading && loading && <div className="z-50 absolute pt-4 top-0 left-0 bottom-0 right-0 bg-stone-950/35 w-full flex justify-center">
                <span className="text-white">Loading...</span>
            </div>}
		</div>
	)
}

export default HotelPopup;

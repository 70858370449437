import {createSlice} from "@reduxjs/toolkit";
import dayjs from 'dayjs';

const today = dayjs();
const defaultStartDate = today.add(1, 'day');
const defaultEndDate = today.add(3, 'day');


let initialState = {
	destination: null,
	nationality: 'PK',
	ratings: [0, 1, 2, 3, 4, 5, 6],
	startDate: "",
	endDate: "",
	hotelName: "",
	guests: [
		{
			adult: 1,
			children: 0,
			ages: []
		}
	],
};

if(alhb_obj.filters && (alhb_obj.filters).hasOwnProperty('destination')){
	initialState = {...alhb_obj.filters};
}


const sliceObj = {
    name: 'filter',
    initialState: initialState,
    reducers: {
        setDestination: (state, action) => {
            state.destination = action.payload;
        },
        setNationality: (state, action) => {
            state.nationality = action.payload;
        },
        setDates: (state, action) => {
            state.startDate = action.payload[0];
            state.endDate = action.payload[1];
        },
        addNewRoom: (state, action) => {
            state['guests'].push(action.payload);
        },
        removeRoom: (state, action) => {
            state['guests'].splice(action.payload.index, 1);
        },
        setAdult: (state, action) => {
            state['guests'][action.payload.index]['adult'] = action.payload.adult;
        },
        setChildren: (state, action) => {

            const currentChildren = state['guests'][action.payload.index]['children'];
            const newChildren = action.payload.children;
            const currentLength = state['guests'][action.payload.index]['ages'].length;

            if(newChildren > currentChildren){
                for(let i = 0; i < (newChildren - currentLength); i++){
                    state['guests'][action.payload.index]['ages'].push(1);
                }
            }

            if(newChildren < currentChildren){
                for(let i = currentChildren; i > newChildren; i--){
                    state['guests'][action.payload.index]['ages'].pop();
                }
            }

            state['guests'][action.payload.index]['children'] = newChildren;
        },
        setRating: (state, action) => {
            state.ratings = action.payload.ratings;
        },
        setHotelName: (state, action) => {
            state.hotelName = action.payload.hotelName;
        },
		setFilter: (state, action) => {
			state = action.payload;
		}


    }
}

export const filterSlice = createSlice(sliceObj);

export const {
    setDestination,
    setDates,
    setGuests,
    addNewRoom,
    removeRoom,
    setAdult,
    setChildren,
    setNationality,
    setRating,
    setHotelName,
	setFilter
} = filterSlice.actions;

export default filterSlice.reducer;

import { DatePicker, Space, Form } from "antd";
import { useAlfgoState } from "../../AlfgoContext.jsx";
import dayjs from 'dayjs';
import { useState } from "react";
const { RangePicker } = DatePicker;

const CheckDates = () => {
    const [nights, setNights] = useState(0);
    const { dispatch } = useAlfgoState(); // Using the context hook

    const today = dayjs();
    const disabledDate = (current) => {
        return current && current.isBefore(today);
    };

    const handleDateChange = (dates, dateStrings) => {
        dispatch({ type: 'SET_DATES', payload: dateStrings }); // Dispatching context action
        const differenceInDays = dates[1].diff(dates[0], 'day');
        setNights(differenceInDays);
    };

    const rangePickerConfig = {
        size: "large",
        disabledDate,
        onChange: handleDateChange,
        placeholder: ['Check In', 'Check Out'],
        required: true,
        dropdownClassName: "single-panel",
        mode : ['date', 'date'],
        showTime: false,
    };

    const rules = [
        {
            required: true,
            message: "Check in and checkout dates are required."
        }
    ];

    return (
        <div className="alhb-hero__input-group">
            <div className="alhb-hero__input-wrap">
                <Space id=""  direction="vertical" size={32} style={{width: "100%"}}>
                    <Form.Item label="Select Dates" rules={rules} name="checkDates">
                        <RangePicker {...rangePickerConfig} />
                    </Form.Item>
                </Space>
            </div>

            <div className="alhb-hero__input-wrap">
                <Form.Item label="Nights" name="nights">
                    {/*<input type="number" value={nights} className="alhb-hero__nights" readOnly />*/}
					<span className='w-100 flex px-4 align-center'>{nights}</span>
                </Form.Item>
            </div>
        </div>
    );
}

export default CheckDates;


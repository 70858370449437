import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './App.css';
import './main.css';
import { AlfgoProvider } from './AlfgoContext.jsx';

const el = document.querySelector( '.alhb-hero-main' );
if ( el ) {
	ReactDOM.createRoot( el ).render(
		<React.StrictMode>
			<AlfgoProvider>
				<App />
			</AlfgoProvider>
		</React.StrictMode>,
	);
}

import { Form, Button } from 'antd';
import {HotelName, Ratings, Guests, CheckDates, Nationalities, Destinations} from "./inputs";
import qs from 'qs';
import {useEffect} from "react";
import {useAlfgoState} from "../AlfgoContext.jsx";


function FormComponent() {
	const [form] = Form.useForm();
	const {state, dispatch} = useAlfgoState();

    const flattenObject = (obj, parent = '', res = {}) => {
        for (let key in obj) {
            const propName = parent ? `${parent}[${key}]` : key;
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                flattenObject(obj[key], propName, res);
            } else {
                res[propName] = obj[key];
            }
        }
        return res;
    };

	const formData = state.filter;
	const initialValues = formData;
	// const dispatch = useDispatch();
	const onFinish = (values) => {
		// console.log(values);
		// console.log(formData);
		// return;
		// const queryString = qs.stringify(formData, { arrayFormat: 'brackets' });
		dispatch({type: "SET_CART_ITEMS", payload: []});
        const flattenedObject = flattenObject(formData);
        const queryString = new URLSearchParams(flattenedObject).toString();
		const url = `/hotels?${queryString}`;
		window.location.href = url;
	};

	const onFinishFailed = (errorInfo) => {
		// console.log('Failed:', errorInfo);
	};

	useEffect(() => {
		dispatch({ type: 'SET_FILTER', payload: alhb_obj.filters });
		dispatch({ type: 'SET_NATIONALITY', payload: 'PK' });
	}, []);

	useEffect(() => {
		form.setFieldsValue(initialValues);
	}, [form]);

	return (
		<div className="alhb-hero">
			<Form
				form={form}
				layout="vertical"
				className="alhb-hero__form"
				initialValues={initialValues}
				method="get"
				action="/hotels"
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
			>
				<div className="alhb-hero__container">

					<Destinations />

					<CheckDates />

					<Guests form={form}/>

					{/*<Nationalities />*/}

					{/*<Ratings />*/}

					{/*<HotelName />*/}

					<Form.Item className="alhb-hero__btn-container flex flex-col justify-center mb-0">
						<Button type="primary" htmlType="submit" className="mt-2">
							Submit
						</Button>
					</Form.Item>
				</div>

			</Form>
        </div>
	);
}

export default FormComponent;

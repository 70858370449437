import { useState, useEffect, useCallback } from "react";
import { Input, Slider, InputNumber, Form, Spin, Button } from "antd";
import { HotelCard } from "../Hotels";
import { useQuery } from "react-query";
import { getHotels } from "../../api";
import { useAlfgoState } from "../../AlfgoContext";
import FilterRatings from "./FilterRatings.jsx";
import { MinusOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";

export default function HotelsWrap() {
    const { state, dispatch } = useAlfgoState();
    const [hotels, setHotels] = useState([]);
    const [realHotels, setRealHotels] = useState([]);
    const [defaultMin, setDefaultMin] = useState(0);
    const [defaultMax, setDefaultMax] = useState(1000);
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(1000);
    const [filterRatings, setFilterRatings] = useState([0, 1, 2, 3, 4, 5]);
    const [cartOpen, setCartOpen] = useState(false);
    const [toggleCartDetail, setToggleCartDetail] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [isFiltered, setIsFiltered] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);

    useEffect(() => {
        dispatch({ type: "SET_IS_CART_DETAIL", payload: toggleCartDetail });
    }, [toggleCartDetail]);

    useEffect(() => {
        // const sessionCart = localStorage.getItem('currentCart');
        // if ((state.common.cart.items).length === 0 && sessionCart) {
        //     dispatch({ type: "SET_CART", payload: JSON.parse(sessionCart) });
        // }
    }, []);

    let queryParams = {
        'action': "alfg_get_hotels",
        'data': { ...state.filter },
        'nonce': alhb_obj.nonce
    }

    const { data, error, isLoading } = useQuery(['alfgHotels', queryParams], () => getHotels(queryParams), {
        enabled: !!queryParams, // This ensures the query runs only if queryParams is not null
    });

    useEffect(() => {
        if (data && data.hotels && data.hotels.hotel) {
            setHotels(data.hotels.hotel);
            setRealHotels(data.hotels.hotel);
            dispatch({ type: "SET_SESSION_ID", payload: data.generalInfo.sessionId });
            setTimeout(() => {
                setInitialLoad(false);
            }, 300);

        }
    }, [data])

    useEffect(() => {
        const result = realHotels.reduce((acc, hotel) => {
            if (hotel.minPrice < acc.min) acc.min = hotel.minPrice;
            if (hotel.minPrice > acc.max) acc.max = hotel.minPrice;
            return acc;
        }, { min: Infinity, max: -Infinity });

        setMinPrice(result.min);
        setMaxPrice(result.max);

        setDefaultMin(result.min);
        setDefaultMax(result.max);
    }, [realHotels]);

    const filterHotels = useCallback(() => {
        const value = searchValue.toLowerCase();

        const filtered = realHotels.filter(hotel => {
            const matchesNameOrLocation = hotel.name.toLowerCase().includes(value) ||
                hotel.hotelInfo.city.toLowerCase().includes(value) ||
                hotel.hotelInfo.add1.toLowerCase().includes(value) ||
                hotel.hotelInfo.add2.toLowerCase().includes(value);

            const matchesPrice = hotel.minPrice >= minPrice && hotel.minPrice <= maxPrice;
            const matchesRating = filterRatings.includes(parseInt(hotel.hotelInfo.starRating));

            return matchesNameOrLocation && matchesPrice && matchesRating;
        });

        setHotels(filtered);
        setIsFiltered(true);
    }, [searchValue, minPrice, maxPrice, filterRatings, realHotels]);

    useEffect(() => {
        filterHotels();
    }, [filterHotels]);

    const sliderChange = (value) => {
        setMinPrice(value[0]);
        setMaxPrice(value[1]);
    }

    const ratingChange = (value) => {
        setFilterRatings(value);
    }

    return (
        <>
            <div className="alfg-hotels">
                <div className="alfg-hotels__container">
                    <div className="alfg-hotels__sidebar alfg-sidebar">
                        <div className="alfg-sidebar__heading-wrap">
                            <h2 className="alfg-sidebar__heading">Filters</h2>
                        </div>

                        {!isLoading &&
                            <div className="alfg-sidebar__filter-container">
                                <div className="alfg-sidebar__single-filter">
                                    <div className="alfg-sidbar__single-heading-wrap">
                                        <h3>Search by hotel name</h3>
                                    </div>

                                    <div className="alfg-sidbar__single-content-wrap">
                                        <Input onChange={(e) => {
                                            setSearchValue(e.target.value);
                                        }} />
                                    </div>
                                </div>

                                <div className="alfg-sidebar__single-filter">
                                    <div className="alfg-sidbar__single-heading-wrap">
                                        <h3>Price</h3>
                                    </div>

                                    <div className="alfg-sidbar__single-content-wrap"
                                        style={{ display: "flex", flexDirection: "column", gap: "var(--space-4)" }}>
                                        <Slider
                                            max={defaultMax}
                                            min={defaultMin}
                                            range={{ draggableTrack: false }}
                                            defaultValue={[defaultMin, defaultMax]}
                                            onChange={(value) => sliderChange(value)}
                                        />
                                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "8px" }}>
                                            <Form.Item label={"Min"}>
                                                <InputNumber
                                                    value={minPrice}
                                                    min={0}
                                                    max={maxPrice - 1}
                                                    onChange={(value) => setMinPrice(value)}
                                                    readOnly={true}
                                                />
                                            </Form.Item>
                                            <Form.Item label={"Max"}>
                                                <InputNumber
                                                    value={maxPrice}
                                                    min={minPrice + 1}
                                                    onChange={(value) => setMaxPrice(value)}
                                                    readOnly={true}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>

                                <div className="alfg-sidebar__single-filter">
                                    <div className="alfg-sidbar__single-heading-wrap">
                                        <h3>Ratings</h3>
                                    </div>

                                    <div className="alfg-sidbar__single-content-wrap">
                                        <FilterRatings ratingChange={ratingChange} filterRatings={filterRatings} />
                                    </div>
                                </div>
                            </div>}
                    </div>
                    <div className="alfg-hotels__main">
                        {cartOpen &&<div style={{ position: "sticky", top: '100px', zIndex: "10", background: "white" }}>
                            <div className="alfg-cart">
                                <div className={`alfg-cart__head ${cartOpen ? 'opened' : ''}`}
                                    onClick={() => setCartOpen(!cartOpen)}>
                                    <span>Selected Rooms</span>
                                    <span>{(state.common.cart.items).length} <span>{!cartOpen ? <PlusOutlined /> :
                                        <MinusOutlined />}</span></span>
                                </div>


                                    <div className="alfg-cart__body flex flex-col gap-6">
                                        <div className="alfg-cart__container flex flex-col gap-4">
                                            {
                                                (state.common.cart.items).map((item, index) => {
                                                    return (
                                                        <div
                                                            className="alfg-cart__item flex justify-between gap-12 border-b border-solid">

                                                            <span className="flex gap-8">
                                                                {<DeleteOutlined onClick={() => {
                                                                    const cartItems = state.common.cart.items;
                                                                    cartItems.splice(index, 1);
                                                                    dispatch({
                                                                        type: 'SET_CART_ITEMS',
                                                                        payload: [...cartItems]
                                                                    });
                                                                }} />}

                                                                {item.room.roomName}
                                                            </span>

                                                            <span>AED {(item.room.price.net).toFixed(2)}</span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>

                                        {(state.common.cart.items).length > 0 && <Button
                                            className="w-max"
                                            type="primary"
                                            onClick={(e) => setToggleCartDetail(!toggleCartDetail)}
                                        >
                                            Confirm Booking
                                        </Button>}
                                    </div>

                            </div>
                        </div>
                        }
                        <div className="alfg-hotels__grid-container">
                            {
                                isLoading &&
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <Spin size="large">
                                    </Spin>
                                </div>
                            }

                            {
                                error &&
                                <div>An error has occurred: {error.message}</div>
                            }

                            {!isLoading && !error && isFiltered && !initialLoad && (
                                hotels.length === 0 ? (
                                    <div
                                        style={{ textAlign: "center", padding: "20px", fontSize: "18px", color: "#888" }}>
                                        No matching hotels found
                                    </div>
                                ) : (
                                    hotels.map((singleHotel) => {
                                        return (
                                            <HotelCard
                                                key={singleHotel.code}
                                                hotel={singleHotel}
                                            />
                                        );
                                    })
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

import {useState, useRef, useEffect} from "react";
import {InputNumber, Select, Form, Space} from "antd";
import {useAlfgoState} from "../../AlfgoContext.jsx";

const Guests = ({form}) => {
    const {state, dispatch} = useAlfgoState(); // Using the context hook
    const {guests} = state.filter; // Accessing guests from the context state
    const [activeContainer, setActiveContainer] = useState(false);
    const guestContainer = useRef(null);
    const [guestString, setGuestString] = useState("");

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                guestContainer.current &&
                !guestContainer.current.contains(event.target) &&
                !event.target.classList.contains('alhb-hero__guest-input')
            ) {
                setActiveContainer(false);
            }
        };

        const handleEscapeKey = (event) => {
            if (event.key === 'Escape') {
                setActiveContainer(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleEscapeKey);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [guestContainer]);
    const ages = Array.from({length: 14}, (v, i) => ({
        value: i + 1,
        label: `${i + 1}`,
    }));

    const addRoomHandle = (e) => {
        e.preventDefault();
        const defaultGuest = {
            adult: 1,
            children: 0,
            ages: [],
        };

        dispatch({type: "ADD_NEW_ROOM", payload: defaultGuest}); // Dispatching context action
    };

    const handleAdultChange = (value, index) => {
        const payload = {index, adult: value};
        dispatch({type: "SET_ADULT", payload}); // Dispatching context action
    };

    const handleChildrenChange = (value, index) => {
        const payload = {index, children: value};
        dispatch({type: "SET_CHILDREN", payload}); // Dispatching context action
    };

    const removeRoomHandle = (e, index) => {
        e.preventDefault();
        const payload = {index};
        dispatch({type: "REMOVE_ROOM", payload}); // Dispatching context action
    };

    const _s = (count, single, multi) => {
        return count === 1 ? single : multi;
    };

    useEffect(() => {
        if (guestContainer.current) {
            guestContainer.current.scrollTop = guestContainer.current.scrollHeight;
        }

        const rooms = guests.length;
        let persons = 0;

        guests.forEach((guest) => {
            persons += guest.adult + guest.children;
        });

        const guestStringNew = `${persons} ${_s(
            persons,
            "person",
            "persons"
        )} in ${rooms} ${_s(rooms, "room", "rooms")}`;
        setGuestString(guestStringNew);
    }, [guests]);

    return (
        <div className="alhb-hero__input-wrap">
            <div className="alhb-hero__guest-wrap">
                <Form.Item label="Room information">
                    <input
                        className="alhb-hero__guest-input"
                        onClick={() => setActiveContainer(!activeContainer)}
                        value={guestString}
                        readOnly
                    />
                </Form.Item>

                {activeContainer && (
                    <div className="alhb-hero__guest-container" ref={guestContainer}>

                        {guests.map((guest, index) => (
                            <div key={index} className="alhb-hero__guest-input">
                                <span style={{display: "flex", gap: "0.5rem"}}>
                                    <strong>Room {index + 1}</strong>
                                    {index > 0 && (
                                        <a href="" onClick={(e) => removeRoomHandle(e, index)}>
                                            Remove
                                        </a>
                                    )}
                                </span>

                                <div style={{
                                    display: "grid",
                                    gap: "1rem",
                                    gridTemplateColumns: "repeat(2, minmax(0, 2fr)"
                                }}>
                                    <Form.Item label="Adult" name={['guests', index.toString(), 'adult']}>
                                        <InputNumber
                                            min={1}
                                            max={9}
                                            defaultValue={guest.adult}
                                            onChange={(value) => handleAdultChange(value, index)}
                                            style={{width: "100%"}}
                                        />
                                    </Form.Item>

                                    <Form.Item label="Children" name={['guests', index.toString(), 'children']}
                                               style={{width: "100%"}}>
                                        <InputNumber
                                            min={0}
                                            max={4}
                                            defaultValue={guest.children}
                                            onChange={(value) => handleChildrenChange(value, index)}
                                            style={{width: "100%"}}
                                        />
                                    </Form.Item>
                                </div>

                                <div style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(3, minmax(0, 1fr)",
                                    gap: "0.5rem"
                                }}>
                                    {guest.children > 0 &&
                                        guest.ages.map((age, ageIndex) => (
                                            <Form.Item label={`Child ${ageIndex + 1} age`} key={`age-${ageIndex}`}
                                                       name={['guests', index.toString(), 'ages', ageIndex.toString()]}>
                                                <Select
                                                    key={`age-select-${ageIndex}`}
                                                    options={ages}
                                                    style={{width: "100%"}}
                                                    defaultValue={age}
                                                />
                                            </Form.Item>
                                        ))}
                                </div>
                            </div>
                        ))}

                        <a href="/" onClick={(e) => addRoomHandle(e)}>
                            Add room
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Guests;

import { useState, useEffect, useRef } from 'react';
import { Select, Form } from 'antd';
import { useAlfgoState } from '../../AlfgoContext.jsx';
import { setDestination } from '../../reducer/filter.js';

function Destinations() {
	const { state, dispatch } = useAlfgoState();
	const destinationRef = useRef(null);
	const [destinationOptions, setDestinationOptions] = useState([]);

	const onChange = (value) => {
		dispatch({ type: 'SET_DESTINATION', payload: value });
	};

	const onSearch = (value) => {
		// Your onSearch logic here
	};

	const filterOptions = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	useEffect(() => {
		const allDestinations = [];
		Object.entries(window.alhb_obj.destinations).map(([key, value]) => {
			allDestinations.push({ value: key, label: value })
		});
		setDestinationOptions(allDestinations);
	}, []);

	const rules = [
		{
			required: true,
			message: "Destination is required"
		}
	];

	return (
		<div className="alhb-hero__input-wrap">
			<Form.Item
				name="destination"
				label="Location"
				rules={rules}>
				<Select
					showSearch
					placeholder="Select Destination"
					name="destination"
					optionFilterProp="children"
					onChange={onChange}
					onSearch={onSearch}
					filterOption={filterOptions}
					options={destinationOptions}
					size="large"
					style={{ width: "100%" }}
				/>
			</Form.Item>
		</div>
	);
}

export default Destinations;

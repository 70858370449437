import FormComponent from './components/Form.jsx';
import {useEffect} from "react";
import {HotelsWrap} from './components/Hotels';
import {useAlfgoState} from "./AlfgoContext.jsx";
import {CartDetail} from "./components/CartDetail";
import {ConfigProvider} from "antd";
import {QueryClient, QueryClientProvider} from "react-query";

function App() {
	const {state, dispatch} = useAlfgoState();
	const queryClient = new QueryClient();
	// const dispatch = useDispatch();
	const isHotels = state.common.isHotels;
	const formData = state.filter;

	useEffect(() => {
		dispatch({ type: 'SET_IS_HOTELS', payload: alhb_obj.isHotels });

		if(alhb_obj.filters && (alhb_obj.filters).hasOwnProperty('destination')){
			dispatch({ type: 'SET_IS_HOTELS', payload: alhb_obj.filters });
		}

		const urlParams = new URLSearchParams(window.location.search);
		if (urlParams.has('missing_guest_data') && (state.common.cart.items).length > 0) {
			dispatch({ type: 'SET_IS_CART_DETAIL', payload: true });
			dispatch({ type: 'SET_IS_HOTELS', payload: true });
		}

        jQuery('body').on('removed_from_cart', function(){
			const data = {
				action: 'get_cart_data',
				nonce: alhb_obj.nonce,
			};

			jQuery.post(alhb_obj.ajaxUrl, data, function(resp){
				if(resp.status === 'success'){
					dispatch({ type: 'SET_CART', payload: resp.data });
					// console.log(resp.data);
				}
			});
        });

	}, []);

	return (
		<QueryClientProvider client={queryClient} >
			<ConfigProvider theme={{
				token: {
					// Seed Token
					colorPrimary: '#ff661a'
				},
			}}>

				{!isHotels && <FormComponent />}

				{
					isHotels && !(state.common.isCartDetail) &&
						<HotelsWrap />
				}

				{
					isHotels && (state.common.isCartDetail) &&
					<CartDetail />
				}
			</ConfigProvider>
		</QueryClientProvider>
	);
}

export default App;
